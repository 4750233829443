<template>
<form @submit.prevent="">

    <div v-if="editMode" class="form-row">
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان پوشه" v-model="newCategory"></v-text-field>
        <div v-if="!isMobile"  class="col-12 col-md-12">
            <input type="text" placeholder="عنوان پوشه" class="widget-input input-no-bg input-lg input-set-border  w-100" v-model="newCategory">
        </div>
        <div class="col-12 col-md-12 text-center pt-3 pb-2" v-if="!isMobile" >
            <span @click="saveCategory()" :class="'tgju-btn tgju-btn-lg min-w150 tgju-btn-primary ' + (addEditLoading ? 'loading-active inline-flex' : '')">
                <i class="uil uil-edit-alt"></i>
                <span class="span-title">ویرایش پوشه</span>
            </span>
            <span v-if="editMode" @click="cancelEditCategory()" class="tgju-btn m-right tgju-btn-lg min-w150 tgju-btn-light">
                <i class="uil uil-times"></i>
                <span class="span-title">لغو ویرایش</span>
            </span>
        </div>
    </div>

    <div v-if="isMobile && editMode" class="form-row form-row-mobile-flex-modal" style="margin-bottom:5px;">
        <div class="col-12 col-md-4">
            <span @click="saveCategory()" :class="'tgju-btn tgju-btn-lg min-w150 tgju-btn-primary ' + (addEditLoading ? 'loading-active inline-flex' : '')">
                <i class="uil uil-edit-alt"></i>
                <span class="span-title">ویرایش پوشه</span>
            </span>
            <span v-if="editMode" @click="cancelEditCategory()" class="tgju-btn m-right tgju-btn-lg min-w150 tgju-btn-light">
                <i class="uil uil-times"></i>
                <span class="span-title">لغو ویرایش</span>
            </span>
        </div>
    </div>


    <div class="form-row" v-if="isMobile && !editMode">
        <div class="list-widget list-dash-items modal-list-body m-0">
            <div v-for="category in data.categories" :key="category.id" class="list-row item-draggable hover bold">
                <div class="list-col l-title ">
                    <span>{{ category.title }}</span>
                </div>
                <div class="list-col l-item pointer" v-if="category.user_id" @click="goEditCategory(category.id, category.title)">
                    <i class="uil uil-edit-alt"></i>
                </div>
                <div class="list-col l-item red-color pointer" @click="removeCategory(category.id)" v-if="category.user_id">
                    <i class="uil uil-trash"></i>
                </div>
                <div v-if="!category.user_id" class="list-col l-item list-col-inactive">
                    <i class="uil uil-edit-alt"></i>
                </div>
                <div v-if="!category.user_id" class="list-col l-item red-color list-col-inactive">
                    <i class="uil uil-trash"></i>
                </div>
            </div>
        </div>
    </div>

    <slot v-if="!isMobile">
        <div class="list-widget list-dash-items modal-list-body m-0">
            <div v-for="category in data.categories" :key="category.id" class="list-row item-draggable hover bold">
                <div class="list-col l-title ">
                    <span>{{ category.title }}</span>
                </div>
                <div class="list-col l-item pointer" v-if="category.user_id" @click="goEditCategory(category.id, category.title)">
                    <i class="uil uil-edit-alt"></i>
                </div>
                <div class="list-col l-item red-color pointer" @click="removeCategory(category.id)" v-if="category.user_id">
                    <i class="uil uil-trash"></i>
                </div>
                <div v-if="!category.user_id" class="list-col l-item list-col-inactive">
                    <i class="uil uil-edit-alt"></i>
                </div>
                <div v-if="!category.user_id" class="list-col l-item red-color list-col-inactive">
                    <i class="uil uil-trash"></i>
                </div>
            </div>
        </div>
    </slot>

</form>
</template>

<style lang="scss" scoped>
.cancel-edit-btn {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    color: #1d1c1c;
}
</style>

<script>
// این کامپوننت مودال لیست پوشه ها و یا دسته بندی های اسناد و یاداشت ها می باشد که 
// می توان ویرایش کرد انها را و یا حذف کرد
export default {
    name: 'NoteCategoriesListModal',
    props: ['data'],
    components: {},
    data: function () {
        return {
            newCategory: '',
            editMode: false,
            editId: null,
            addEditLoading: false,
            isMobile:false,
        }
    },
    mounted() {
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // برای سوییچ به لیوات ویرایش
        goEditCategory(id, title) {
            this.editMode = true;
            this.editId = id;
            this.newCategory = title;
        },
        // مخفی سازی ویرایش
        cancelEditCategory() {
            this.editMode = false;
            this.editId = null;
            this.newCategory = '';
        },
        // هندل کردن درخواست ویرایش
        saveCategory() {
            if (this.addEditLoading == false) {
                if (this.editMode) {
                    this.editCategory();
                } else {
                    this.addCategory();
                }
            }
        },
        // این تابع برای ایجاد پوشه اسناد است که فعلا غیرفعال می باشد 
        // به دلیل جدا سازی مودال های ایجاد و ویرایش پوشه ها از یکدیگر
        addCategory() {
            if (!this.newCategory) return;
            this.addEditLoading = true;

            let api_data = {
                title: this.newCategory,
                extension: this.data.widget.type,
            };

            this.$helpers.makeRequest('POST', '/category/store', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let category = api_response.data.response.category;

                    this.$parent.emitData('addCategory', { category: this.$helpers.unbindObject(category) });
                    this.newCategory = '';
                    this.$swal.fire({
                        icon: "success",
                        title: "پوشه با موفقیت ویرایش شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ویرایش پوشه رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                }
            });
        },
        // این تابع برای ویرایش پوشه و دسته بندی اسناد و یا یادداشت‌ها است
        editCategory() {
            if (!this.newCategory) {
                return;
            }

            this.addEditLoading = true;

            let api_data = {
                title: this.newCategory,
            };

            this.$helpers.makeRequest('POST', '/category/edit/' + this.editId, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let category = api_response.data.response.category;

                    this.$parent.emitData('editCategory', { category: this.$helpers.unbindObject(category) });

                    this.cancelEditCategory();
                    this.$swal.fire({
                        icon: "success",
                        title: "پوشه با موفقیت ویرایش شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ویرایش پوشه رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                }
            });
        },
        // این تابع برای حذف پوشه یا دسته بندی استفاده می شود
        removeCategory(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این دسته اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    this.$helpers.makeRequest('DELETE', '/category/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);
                        if (api_response.status == 200) {
                            this.$parent.emitData('removeCategory', { id });
                            this.$swal.fire({
                                icon: "success",
                                title: "پوشه با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف پوشه رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // جهت بستن مودال استفاده می شود
        saveChanges() {
            this.$parent.closeModal();
        },
    },
}
</script>
